import api from '@/utils/api'

export default {
    getArticles(page, size) {
        const range = `?page=${page}&per_page=${size}`
        return api.get('article/filtered' + (page ? range : '') + `&sort=${encodeURIComponent('[date, DESC]')}`)
    },

    getArticle(id) {
        return api.get(`article/${id}`)
    }
}
